import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import "./calendarPage.sass";

export default function CalendarPage() {
    return (
        <main>
            <Header></Header>
                <h1>Calendrier de page</h1>
                <Link to="/connect">
                    <button>Connect</button>
                </Link>
            <Footer></Footer>
        </main>
    )
}