import React from "react";
import { Link }  from "react-router-dom";
import "./partnerships.sass";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

export default function Partnerships () {
    return (
        <main>
            <Header></Header>
            <section className="button-toggle">
                <div className="d-flex">
                    <label className="switch">
                        <input className="switch-input" type="checkbox" />
                        <span className="switch-label" data-on="partenariat" data-off="calendrier"></span> 
                        <span className="switch-handle"></span> 
                    </label>
                </div>
            </section>
            <section className="info_entreprise">
                <h2>En cours:</h2>
                <article className="card-inset">
                    <p className="titre_carte">Avec nomentreprise @instagram</p>
                    <p>Date et heure</p>
                    <button className="button-canard w100">Voir la rémunération convenue</button>
                    <div className="d-flex">
                        <button className="button-canard w-50">Voir le profil de l’entreprise</button>
                        <button className="button-canard w-50">Envoyer un message</button>
                    </div>
                </article>
            </section>
            <Footer></Footer>
        </main>
    )
}


