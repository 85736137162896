import React from 'react';
import "./header.sass";
import { Link } from "react-router-dom";
import iconeProfile from "../../assets/images/icones/icone-profil.png"
import logoInspy from "../../assets/images/icones/logo_inspy.png"


const Header = () => {
    return (
        <header>
            <Link to="/home">
                <img className="logo_inspy" src={logoInspy} />
            </Link>
            <Link to="/settings">
                <img className="" src={iconeProfile} />
            </Link>
        </header>
    );
}

export default Header;


