import React from "react";
import { Link } from "react-router-dom";
import "./messages.sass";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer"

export default function Messages () {
    return (
        <main>
            <Header></Header>
            <section className="all_msg">
                <Link to="/message">
                    <article className="all_msg_single1">
                        <img className="profile" src="https://scontent.frns1-1.fna.fbcdn.net/v/t51.2885-15/221220838_507066713927329_8088485933375729191_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=86c713&_nc_ohc=gzMDgcQXfycAX_V4y7l&_nc_ht=scontent.frns1-1.fna&edm=AL-3X8kEAAAA&oh=00_AT9S5Sc2fjWLlsqd1_GWLjvO5PYb1PUwI3is1pbTp31XXA&oe=62ADCABA" alt="icone profile"/>
                        <div className="profile_preview">
                            <p className="profile_preview_title">Nom entreprise @instagram</p>
                            <div className="d-flex">
                                <p>Lorem ipsum dolor sit amet...</p>
                                <p>9:00</p>
                            </div>
                        </div>
                    </article>
                </Link>
            </section>
            <Footer></Footer>
        </main>
    )
}