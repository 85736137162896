import React from 'react';
import "./footer.sass";
import { Link }  from "react-router-dom";
import iconeHome from "../../assets/images/icones/icone_home1.png";
import iconeMessage from "../../assets/images/icones/icone-msg.png"
import iconeCalendar from "../../assets/images/icones/icone_calendar.png"


const Footer = () => {
    return (
        <footer>
            <Link to="/home">
                <img src={iconeHome} />
            </Link>
            <Link to="/messages">
                <img src={iconeMessage}/>
            </Link>
            <Link to="/calendarPage">
                <img src={iconeCalendar}/>
            </Link>  
        </footer>
    );
}

export default Footer;