import React from "react";
import { Link } from "react-router-dom";
import "./connect.sass"

function Connect () {
    return (
        <React.StrictMode>
            <div className="connect_flex">
                <img src="https://uploads-ssl.webflow.com/616689699159c03cad7e1881/6166b6d324baad3c8704fbfb_logonoir.png" />
                <Link to="/home">
                    <button>Continue with Facebook</button>
                </Link>
             </div>
        </React.StrictMode>
    )
}

export default Connect;