
import './styles.sass';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Connect from './screens/connect/connect';
import Settings from './screens/settings/settings';
import Partnerships from './screens/partnerships/partnerships';
import Message from './screens/message/message';
import Messages from './screens/messages/messages';
import Home from './screens/home/home';
import CalendarPage from './screens/calendarPage/calendarPage';
import ProfilePage from './screens/profilePage/profilePage';
import ProfileModification from './screens/profileModification/profileModification';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    
      <Routes>
        <Route path="/app" element={<App/>}/>
        <Route path="/" element={<Connect/>}/>
        <Route path="/connect" element={<Connect/>}/>
        <Route path="/profilePage" element={<ProfilePage/>}/>
        <Route path="/settings" element={<Settings/>}/>
        <Route path="/partnerships" element={<Partnerships/>}/>
        <Route path="/messages" element={<Messages/>}/>
        <Route path="/message" element={<Message/>}/>
        <Route path="/home" element={<Home/>}/>
        <Route path="/calendarPage" element={<CalendarPage/>}/>
        <Route path="/profileModification" element={<ProfileModification/>}/> 
      </Routes>
    
  </BrowserRouter>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
