import React from "react";
import "./settings.sass";
import { Link }  from "react-router-dom";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";


function Settings () {
    return (
        <React.StrictMode>
            <Header></Header>
            <main>
                <section className="section_haut">
                    <img src="https://cdn-icons-png.flaticon.com/512/4091/4091450.png" />
                    <div>
                        <p>Nom de l'entreprise</p>
                        <p>@Instgram</p>
                    </div>
                </section>
                <section className="section_settings">
                    <div className="button_settings">
                        <Link to="/profileModification">
                            <button className="button-canard">Modifier la page l'entreprise</button>
                        </Link>
                        <Link to="/connect">
                            <button className="button-canard">Me déconnecter</button>
                        </Link>
                    </div>
                    <div className="info_settings">
                        <a>Nous contacter</a>
                        <a>Mentions légales</a>
                        <a>Politique de confidentialité</a>
                        <a>Règlement et condition d'utilisation</a>
                    </div>
                </section>
            </main>
            <Footer></Footer>
        </React.StrictMode>
    )
}

export default Settings;