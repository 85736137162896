import React from "react";
import { Link }  from "react-router-dom";
import "./message.sass";
import iconePhoto from "../../assets/images/icones/icone_photo.png"
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

export default function Message () {
    return (
        <main>
            <Header></Header>
            <section className="single_msg">
                <article className="single_msg_1">
                    <img className="profile" src="https://scontent.frns1-1.fna.fbcdn.net/v/t51.2885-15/221220838_507066713927329_8088485933375729191_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=86c713&_nc_ohc=gzMDgcQXfycAX_V4y7l&_nc_ht=scontent.frns1-1.fna&edm=AL-3X8kEAAAA&oh=00_AT9S5Sc2fjWLlsqd1_GWLjvO5PYb1PUwI3is1pbTp31XXA&oe=62ADCABA" alt="icone profile"/>
                    <div className="profile_preview">
                        <p className="profile_preview_title">Nom entreprise @instagram</p>
                        <div className="ligne d-flex">
                            <span>•</span>
                            <p>Connecté</p>
                        </div>
                    </div>
                </article>
            </section>
            <section className="bulle">
                <div className="bulle_send">
                    <div className="bulle_recept_1">
                        <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque non semper turpis, eget dignissim nulla. In lobortis mi a urna rutrum, vitae pulvinar quam consequat.
                        </p>
                    </div>
                </div>
                <div className="bulle_recept">
                    <div className="bulle_recept_2">
                        <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque non semper turpis, eget dignissim nulla. In lobortis mi a urna rutrum, vitae pulvinar quam consequat.
  
                        </p>
                    </div>
                </div>
            </section>
            
            <section className="msg_input">
                <img src={iconePhoto}/>
                <textarea type="text"/>
            </section>
            <Footer></Footer>
        </main>
    )
}