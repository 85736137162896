import React from "react";
import { Link }  from "react-router-dom";
import "./profileModification.sass";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import iconeProfil from "../../assets/images/icones/icone-profil.png"
import iconePlus from "../../assets/images/icones/icone_plus.png"

export default function ProfileModification () {
    return (
        <main className="profileModification">
            <Header></Header>
                <section className="header-profil">
                    <div className="d-flex">
                        <img src={iconeProfil} alt="Profile picture"/>
                        <img className="button-add" src={iconePlus} alt="button add" />
                        <div className="border-input">
                            <div className="d-flex">
                                <p>Nombre d'Abonnés </p>
                                <input type="checkbox"></input>
                            </div>
                            <div className="d-flex">
                                <p>Nombre d'Abonnés </p>
                                <input type="checkbox"></input>
                            </div>
                            <div className="d-flex">
                                <p>Nombre d'Abonnés </p>
                                <input type="checkbox"></input>
                            </div>
                            <div className="d-flex">
                                <p>Nombre d'Abonnés </p>
                                <input type="checkbox"></input>
                            </div>
                        </div>
                    </div>
                    <div className="banniere">
                        <button>Contenu mis en avant<img src={iconePlus} alt="Button add" /></button>
                        
                    </div>
                </section>
                <section className="profile-champ">
                    <div className="d-flex">
                        <label>Nom de l'entreprise :</label>
                        <input type="text" />
                    </div>
                    <div className="d-flex">
                        <label>Horaires :</label>
                        <textarea type="text"  className="text-zone"/>
                    </div>
                    <div className="d-flex">
                        <label>Localisation :</label>
                        <input/>
                    </div>
                    <div className="d-flex">
                        <label>Descriptions :</label>
                        <textarea type="text"  className="text-zone" />
                    </div>
                    <div className="d-flex">
                        <label>Dotations :</label>
                        <textarea type="text"  className="text-zone"/>
                    </div>
                    <div className="d-flex button-save">
                        <Link to="/settings">
                            <button className="button-canard">Sauvegarder</button>
                        </Link>
                    </div>
                    <p>Nous contacter</p>
                    <p>Mentions légales</p>
                    <p>Politique de confidentialité</p>
                    <p>Règlement et conditions d'utilisation</p>
                </section>
            <Footer></Footer>
        </main>
    )
}