import React from "react";
import "./profilePage.sass";
import { Link }  from "react-router-dom";
import Footer from "../../components/footer/footer";
import logoEntreprise from "../../assets/images/placeholders/logo_aventure.png"
import Cross from "../../assets/images/icones/cross.png"
import iconeArrow from "../../assets/images/icones/icone_arrow.png"

function ProfilePage() {
    return (
        <main>
            <section className="img_entreprise" style={{background: 'url("https://dynamic-media-cdn.tripadvisor.com/media/photo-o/0a/37/78/fe/la-prison-st-michel.jpg?w=1200&h=-1&s=1  ") center no-repeat'}} >
                <Link to="/home">
                    <img className="cross" src={Cross}/>
                </Link>
                <a>
                    <img className="arrow" src={iconeArrow}/>
                </a>
            </section>
            <section className="info">
                <img src={logoEntreprise}/>
                <a href="#">@laventure_rennes</a>
                <div className="info_insta">
                    <p>000 Publications</p>
                    <p>000 Abonnés</p>
                    <p>000 Abonnements</p>
                </div>
                <p>« La vie continue, l’aventure nous appelle »
                L’Aventure, le bar de nuit incontournable à Rennes depuis 1978.
                Dansez sur des musiques des années 80 du mardi au samedi de 18h à 3h !
                Un lieu @LaPrisonStMichel </p>
                <div className="card-inset">
                    <h3>Dotation proposée</h3>
                    <p>Pour trois posts et un réel :</p>
                    <ul>
                        <li>Deux cocktails et une bouteille offerte</li>
                    </ul>
                    <p>Propositions de rémunération accéptées</p>
                </div>
                <div className="flex-card">
                    <div className="card-inset w50">
                        <h3>Horaires :</h3>
                        <p>Du mardi au samedi de 18h à 3h</p>
                    </div>
                    <div className="card-inset w50">
                        <h3>Titre de l’info :</h3>
                        <p>Chiffre/Info en question</p>
                    </div>
                </div>
                <img src="https://www.breizhoo.fr/wp-content/uploads/2021/08/4.jpg" />
                <h2>Disponibilités de l’entreprise :</h2>
                <p>Insérer planning</p>
                <div className="flex-button">
                    <Link to="/home">
                        <button>Accepter le partenariat avec la dotation</button>
                    </Link>
                    <Link to="/connect">
                        <button>Faire une proposition de rémunération</button>
                    </Link>
                </div>
            </section>
            <Footer></Footer>
        </main>
    )
}

export default ProfilePage;