import React from "react";
import { Link }  from "react-router-dom";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards } from "swiper";
import "swiper/scss";
import "swiper/scss/effect-cards";
import Cross from "../../assets/images/icones/cross.png"
import Heart from "../../assets/images/icones/heart.png"
import "./home.sass";


export default function Home () {
    return (
        <main>
            <Header></Header>
                <section className="swiper_card">
                
                <Swiper
                    effect={"cards"}
                    grabCursor={true}
                    modules={[EffectCards]}
                    className="mySwiper"
                    centeredSlideS="true"
                    autoHeight="true"
                >
                    <SwiperSlide style={{background: 'url(" https://uploads-ssl.webflow.com/616689699159c0df667e18b9/62a70e418f7fbc02aef1b66a_61977850be798d79c68ca630_6179ac90fc74623a78b8ea13__MG_9703.jpg ") center'}}>
                        <div class="carte">
                            <div className="button_card">
                                <Link to="/profilePage">
                                    <button className="button-canard ">Plus d'info</button>
                                </Link>
                            </div>
                        </div>
                            
                        
                    </SwiperSlide>
                    <SwiperSlide style={{background: 'url(" https://uploads-ssl.webflow.com/616689699159c0df667e18b9/62a722a6fa0ea1b59c2ef14c_622f13902e95fe5fb540e82e_DSC_2601.jpg ") center center'}}>
                    <div class="carte">
                            <div className="button_card">
                                <Link to="/profilePage">
                                    <button className="button-canard ">Plus d'info</button>
                                </Link>
                            </div>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide style={{background: 'url(" https://uploads-ssl.webflow.com/616689699159c0df667e18b9/62a722a6fa0ea1b59c2ef14c_622f13902e95fe5fb540e82e_DSC_2601.jpg ") center center'}}>
                    <div class="carte">
                            <div className="button_card">
                                <Link to="/profilePage">
                                    <button className="button-canard ">Plus d'info</button>
                                </Link>
                            </div>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide style={{background: 'url(" https://uploads-ssl.webflow.com/616689699159c0df667e18b9/62a722a6fa0ea1b59c2ef14c_622f13902e95fe5fb540e82e_DSC_2601.jpg ") center center'}}>
                    <div class="carte">
                            <div className="button_card">
                                <Link to="/profilePage">
                                    <button className="button-canard ">Plus d'info</button>
                                </Link>
                            </div>
                    </div>
                    </SwiperSlide>
                    
                </Swiper>
                <article className="btn_fav">
                    <div className="d-flex">
                        <button>
                            <img src={Cross}/>
                        </button>
                        <button>
                            <img src={Heart}/>
                        </button>
                    </div>
                </article>
                </section>

            <Footer></Footer>
        </main>
    )
}