import React, { useState, useEffect } from "react";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import Connect from "./screens/connect/connect";
import Settings from "./screens/settings/settings";
import EntreprisePage from "./screens/profilePage/profilePage";



function App() {
  // const [adresses, setAdresses] = useState(false);
  // useEffect(() => {
  //   getAdresses();
  // }, []);
  // function getAdresses() {
  //   fetch('http://192.168.0.21:3001/adresses').then(response => {
  //     return response.text();
  //   }).then(data => {
  //     setAdresses(data);
  //   });
  // }
  return (
    <React.StrictMode>
        <Header></Header>
        
    </React.StrictMode>
  );
}

export default App;
